<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm :title="formTitle"
                       in-progress-message="Updating Assignment, Please wait..."
                       :success-message="successMessage"
                       :action-text="actionText"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="activateAssignment"
                       :hide-action="!canBeActivated"
                       @close="$refs.sideBar.emitToggle()">
      <div v-if="canBeActivated">
        <div class="date-hint">Hint: Please enter date in DD/MM/YYYY hh:mm AM/PM format. For example: 20/02/2020 12:20 AM, 12/12/2020 05:12 PM. Start must be at least 3 minutes from now.</div>
        <div class="date-block">
          <DoodleInput field-name="assignmentStartDate" label-text="Start Date" type="text"
                       left-icon="calendar-alt"
                       rule="required|date_format:dd/MM/yyyy hh:mm a|activation_start_date" :model="doodleForm.assignmentStartDate"
                       v-model="doodleForm.assignmentStartDate" place-holder="DD/MM/YYYY hh:mm AM/PM" :disabled="disableStartDate" @input="upperStart($event)"/>
        </div>

        <div class="date-block">
          <DoodleInput field-name="assignmentEndDate" label-text="End Date" type="text"
                       left-icon="calendar-alt"
                       rule="required|date_format:dd/MM/yyyy hh:mm a|activation_last_date" :model="doodleForm.assignmentEndDate"
                       v-model="doodleForm.assignmentEndDate" place-holder="DD/MM/YYYY hh:mm AM/PM" @input="upperEnd($event)"/>
        </div>

      </div>
      <div v-else class="has-text-centered">
          Please add at least one student and one question to activate.
      </div>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../../../assets/javascript/form-mixin'
import SideBar from '../../Utils/SideBar'
import { eventBus } from '../../../assets/javascript/event-bus'
import { GURU_EVENTS } from '../../../assets/javascript/constants'
import DoodleGeneralForm from '../../Utils/DoodleGeneralForm'
import moment from 'moment'
import DoodleInput from '../../Utils/DoodleInput'

export default {
  name: 'guruActivateAssignment',
  mixins: [formMixin],
  components: { DoodleInput, DoodleGeneralForm, SideBar },
  props: ['instituteCode', 'initData', 'mode'],
  data: function () {
    return {
      doodleForm: {
        assignmentStartDate: null,
        assignmentEndDate: null
      },
      disableStartDate: false
    }
  },
  created () {
    this.$validator.extend('activation_start_date', {
      getMessage: field => 'Start date should be at least 3 minutes from now.',
      validate: (value, [testProp]) => {
        return moment().add(3, 'minutes').unix() < this.getDateAsLong(value)
      }
    })

    this.$validator.extend('activation_last_date', {
      getMessage: field => 'End date should be greater than start date.',
      validate: (value, [testProp]) => {
        return this.getDateAsLong(value) > this.getDateAsLong(this.doodleForm.assignmentStartDate)
      }
    })
  },
  mounted () {
    if (this.initData.startTime) {
      this.doodleForm.assignmentStartDate = this.getDateAsString(this.initData.startTime)

      if (this.initData.startTime < moment().unix() * 1000) {
        this.disableStartDate = true
      }
    }

    if (this.initData.endTime) {
      this.doodleForm.assignmentEndDate = this.getDateAsString(this.initData.endTime)
    }
  },
  methods: {
    activateAssignment () {
      this.doodleForm.errorMessage = ''

      this.executeAPI({
        url: '/api/guru-api/assignment/activateAssignment',
        data: {
          instituteCode: this.instituteCode,
          assignmentId: this.initData.insAssignmentId,
          assignmentStartDate: this.getDateAsLong(this.doodleForm.assignmentStartDate) * 1000,
          assignmentEndDate: this.getDateAsLong(this.doodleForm.assignmentEndDate) * 1000
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.UPDATE_IN_ASSIGNMENT, data.assignment)
        },
        jdaEvent: 'guru-activate-assignment',
        jdaLabel: ''
      })
    },
    getDateAsLong (dte) {
      return moment(dte, 'DD/MM/YYYY hh:mm A').unix()
    },
    getDateAsString (dte) {
      return moment(dte).format('DD/MM/YYYY hh:mm A')
    },
    upperStart (e) {
      this.doodleForm.assignmentStartDate = (e) ? e.toUpperCase() : e
    },
    upperEnd (e) {
      this.doodleForm.assignmentEndDate = (e) ? e.toUpperCase() : e
    }
  },
  computed: {
    canBeActivated () {
      return this.initData.questions.length > 0 && this.initData.students.length > 0
    },
    actionText () {
      if (this.isActivate) {
        return 'Activate'
      } else if (this.isChange) {
        return 'Change'
      }
    },
    successMessage () {
      if (this.isActivate) {
        return 'Assignment Activated Successfully'
      } else if (this.isChange) {
        return 'Assignment Period Changed Successfully'
      }
    },
    formTitle () {
      if (this.isActivate) {
        return 'Activate Assignment'
      } else if (this.isChange) {
        return 'Change Duration'
      }
    },
    isActivate () {
      return this.mode === 'activate'
    },
    isChange () {
      return this.mode === 'change'
    }
  }
}
</script>

<style scoped lang="scss">
  .date-block {
    margin: 1em;
  }

  .date-hint {
    font-size: 0.7em;
    font-weight: bold;
  }

</style>
